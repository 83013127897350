@import "../../../shared/scss/partials/variables";

.selflogo {
    width: 100%;

    text-align: right;
}

.asps_container {
    color: #00263E;
    padding-bottom: 20%;
    .header {
        font-weight: bolder;
        color: $ctbids-dark;

        h1 {
            font-weight: 600;
        }

        h6 {
            color: #a50050 !important;
        }

        .left {
            h1 {
                color: #a50050 !important;
                font-weight: 600;
            }

            h6 {
                color: $ctbids-dark !important;
            }
        }

        .right {
            h4 {
                color: #a50050;
                font-weight: bold;
            }
        }
    }



    @media (max-width: 900px) {
        .center {
            display: block !important;
        }
    }

    .content,
    .header {
        display: flex;
        justify-content: center;
        // align-items: center;
        // margin: 10px 5%;
    }

    .left,
    .c_left {
        // width: 70vw;
        width: 70%;
        align-items: center;
    }

    .right,
    .c_right {
        margin-left: 10%;
        width: 30%;
        align-content: center;
    }

    .c_left {
        // flex-direction: column;
        display: flex;
    }

    .sale-image {
        width: 100px;
        height: 100px;
        resize: both;
        border-radius: 4px;
    }

    h6 {
        color: #00263E !important;
        font-weight: 600;
        padding: 1px 10px;
    }
}

.asp_container {
    padding: 2%;

    .header {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 30px;
    }

    @media (max-width: 900px) {
        .center {
            display: block !important;
        }
    }

    .timetable {
        width: 80px;
        height: 80px;
    }

    .center {
        display: flex;
        width: 100%;
    }

    .title {
        margin: 1px 20px;
        padding-top: 10px;
    }

    .head_cont {
        color: #00263E;
        font-weight: bold;
    }

    .watch-button-sp {
        background-color: #00263E;
        border-radius: 4px;
        margin: 50px 20px;
        color: #fff !important;
        border: none;
        padding: 5px;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
}

.sale_item_list {
    padding: 0px 25px;
}

.itemlist {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    flex-wrap: wrap;
    

    @media (min-width: 1950px) {
        width: calc(110rem);
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 10px;
    }

    .sp-card {
        width: 30%;

        @media only screen and (max-width: 600px) {
            width: 100%;
        }

        @media (min-width: 600px) and (max-width: 992px) {
            width: 40%;
        }
    }
    .item-image {
        // width: 100%;
        height: 100%;
        // height: 30vh;
        // border: 2px solid #c5c5c5;
        border-radius: 2px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        // resize: both;
    }

    .no-image-text {
        font-size: 12px;
    }

    .image-bottom {
        font-size: 12px;
        color: $bottom-text-color;
        height: 15px;
    }

    .item_card {
        border-radius: 4px;
        border: 2px solid #d0de03;
        margin: 25px;
        display: block;
        align-content: center;
        width: 100%;
        // height: 70%;

        .image-card {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .item_text {
            padding-top: 2%;
            // height: 7%;
        }
        text {
            font-size: 14px;
        }

        .watch {
            display: flex;
            justify-content: flex-end;

            button {
                background-color: #00263E;
                border: none;
                color: #fff;
                margin: 10px;
                padding: 5px;
                width: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
            }
        }

        .sp-watching {
            display: flex;
            justify-content: flex-end;

            button {
                background-color: #a50050;
                border: #a50050 1px solid;
                color: #fff;
                margin: 10px;
                padding: 5px;
                width: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;
            }

        }

        text {
            padding: 10px;
        }

    }
}
.asp_container , .aspsi_container {

    .caring-transition {
        .l_header {
            background-color: $location;
            text-align: center;
            color: $white;
            margin-bottom: 10px;
        }

        .body-content {
            background-color: $white;
            // background-color: #8c540b;
            font-size: 12px;
            .navy-text{
                color: $ctbids-dark !important;
            }

            .user-details {
                img {
                    width: 80px;
                    // padding: 2px;
                    // border: 2px solid $outline;
                    // border-radius: 100%;
                }

                .name {
                    padding-left: 5px;

                    .light-color {
                        color: $bottom-text-color;
                    }
                }
            }

            .email,
            .phone,
            .more-sale,
            .website {
                padding-left: 10px;
                margin: 5px 0;

                i {
                    color: $location;
                }
            }

            .favorite-icon {
                padding-left: 10px;
                margin: 10px 0;

                .custom-control-input:checked~.custom-control-label::before {
                    color: $white;
                    border-color: $secondary;
                    background-color: $secondary;
                }
            }

            .phone {
                padding-left: 5px;

                .phone-icon {
                    width: 24px;
                }
            }

            .website {
                span {
                    background-color: #ffd89ed9;
                    padding: 2px 10px;
                    border-radius: 20px;
                    color: #8c540b;
                }
            }
        }
    }
}
.aspsi_container {
    .image {
        margin: 10px 20px;
        height: 300px;
    }

    @media (max-width: 900px) {
        .header {
            display: block !important;
        }
    }


    .itemWatch {
        background-color: #a50050;
        border: 1px solid #a50050;
        color: #fff;
        padding: 5px;
        width: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        text {
            color: #fff;
        }
    }

    .item_left {
        width: "30%" !important
    }


    .title_view {
        margin: 3% 20px;
    }
}

.asp {

    .logobid {
        width: 100px;
        height: 100px;
        margin-top: 0px;
    }

    display: flex;

    .asp_sidemenu {
        background-color: #a50050;
        color: #fff;
        padding: 10px 20px;
    }

    h6 {
        padding: 0px;
    }

    h4 {
        padding: 10px 0px;
    }

    .asp_sidemenu_cont {
        width: max-content;
    }
}

.aspitem_container {

    @media (max-width: 900px) {
        .header {
            display: block !important;
        }
    }

}

.sneak-image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    max-height: 100%;
    max-width: 100%;
}

.react-thumbnail-generator {
    > img {
        width: 100%;
        height: 250px !important;
    }
    .play-icon {
        position: absolute;
        z-index: 1;
    }
}

@media (max-width: 900px) {
    .asp_container {
        .title {
            margin: 0px;
        }
        
        button {
            margin: 20px 0px;
        }
    }
}

.text-navy {
    color: #00263E !important;
}

.sneak-btn {
    background-color: #00263E;
    color: #fff !important;
    border-radius: 4px;
    margin: 50px 20px;
    border: none;
    padding: 5px;
}

.sneak-btn-sale {
    background-color: #00263E;
    color: #fff !important;
    border-radius: 4px;
    margin: 50px 0px;
    border: none;
    padding: 5px;
}

@media only screen and (max-width: 600px) {
    .asps_container {
        padding-bottom: 40% !important;
    }
}

@media (min-width: 600px) and (max-width: 992px) {
    .asps_container {
        padding-bottom: 40% !important;
    }
    .sneak-item-resp {
        margin-bottom: 7rem !important;
    }
}

.p-40 {
    padding: 0 40px;
}

@media (max-width: 610px) {
    .content {
        flex-direction: column;
        border-bottom: 1px solid;
        align-items: center;
    }
    .c_left {
        flex-direction: column;
        padding: 15px 0px 0px 0px;
        width: 100%;
        text-align: center;
    }
    .pd-bt {
        padding-bottom: 15px;
    }
    .right {
        width: none !important;
    }
    .p-40 {
        padding: 0 10px;
    }
    .mb-auction-4 {
        margin-bottom: 0px !important;
    }
    .c_right {
        margin-left: 0px !important;
    }
    .left {
        text-align: center;
    }
    .no-wrap {
        white-space: nowrap;
    }
}

@media (max-width: 480px) {
    .no-wrap {
        white-space: break-spaces !important;
    }
}

.no-wrap {
    white-space: nowrap;
}

.mb-auction-4 {
    margin-bottom: 1.5rem;
}

.mrbt-2 {
    margin-bottom: 2rem;
}

.item-card-title {
    padding: 0px 10px;
}

.sale-content {
    border-bottom: 1px solid rgb(208, 222, 3);
    align-items: center;
    padding: 8px 0px;
}

.sale-image-border {
    border: 1px solid rgb(208, 222, 3);
    border-radius: 5px;
}

@media (min-width: 550px) and (max-width: 910px) {
    .mobile-resp-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;
    }
    .watch-button-sp {
        margin: 50px 0px !important;
    }
    .resp-mr-10 {
        margin-right: 10px;
    }
}

@media (min-width: 210px) and (max-width: 551px) {
    .mobile-resp-item {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
    }
    .watch-button-sp {
        margin: 10px 0px !important;
    }
    .resp-mr-10 {
        margin-right: 10px;
    }
}