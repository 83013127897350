@import "/src/shared/scss/partials/variables";
.vip-membership {
    .premier-banner {
        position: relative;
        text-align: center;
    }
    .membership-header {
        width: 100%;
        // height: 35vh;
        background-repeat: no-repeat;
        background-size: cover;
        color: $white;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    .membership-title {
        width: 100%;
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2.75rem;
        font-weight: bold;
        color: white;
        text-align: center;
    } 
    .membership-details{
        padding: 0px 50px;
    }
    .membership-logo{
        width: 350px;
    }

    .membership-logo-content{
        .membership-service-img{
            width: 120px;
        }

    }
    .footer-message{
        margin-top: 100px;
        padding: 0px 100px;
        a{
            text-decoration: underline !important;
        }
    }
   
    .sub-menu{
        text-align: center;
         padding:  20px;
         font-size: 20px;
         .memerbership-serivce{
            display: flex;
         
         .membership-icons{
            width: 100px;
         }
         .membership-service-content{
            width: 33%;
            margin-right: 50px;
           
         
         .membership-service{
            margin-top: 20px;
            text-align: left;
            .service-title{
                font-size: large;
                font-weight: bold;
             }
         }
        }
        }
         
    }
    .footer-content{
        padding: 0% 10%;
        display: flex;
        font-size: 20px;
        align-items: center;
        .img-section{
            .footer-img{
                width: 200px;
            }
        }
    }
    .pd-3 {
        padding: 3rem;
    }
    .ml-3 {
        margin-left: 3rem;
    }
}
@media (max-width: 800px) {

    .vip-membership {
        .membership-header {
            .membership-tilte{
                margin-top: 150px;
                font-size: 50px;
            }
        }
        .membership-logo{
            width: 250px;
        }
        .sub-menu{
           
            text-align: center;
             padding:  20px;
             font-size: 20px;
             .memerbership-serivce{
                display: block;
             
             .membership-icons{
                margin-top: 40px;
                width: 100px;
             }
             .membership-service-content{
                width: 100%;
             }
            }
        }
             
    }
}

@media (max-width: 600px) {
    .vip-membership {
        .membership-logo-content{
            .membership-service-img{
                width: 90px;
            }}
        .membership-header {
            .membership-tilte{
                font-size: 40px;
            }
        }
        
    }
}
@media (max-width: 500px) {
    .vip-membership {
        .membership-logo-content{
            .membership-service-img{
                width: 80px;
            }
        }
        .membership-logo{
            width: 200px;
        }
        .membership-details{
            padding: 0px 10px;
        }
    
        .footer-content{
            display: block;
            align-items: center;
            .img-section{
                text-align: center;
                .footer-img{
                    width: 150px;
                    margin-bottom: 20px;
                }

            }
            
        }
        .footer-message{
            padding: 0px;
        }
    }
 
}

@media (max-width: 400px) {
    .vip-membership {
        .membership-header {
            .membership-tilte{
                font-size: 40px;
            }
        }
        .membership-logo{
            width: 170px;
        }
    }
  
}

@media (min-width: 1520px) and (max-width: 1920px) {
    .membership-header {
        width: 100%;
        // height: 35vh !important;
    }
}

@media (min-width: 1921px) and (max-width: 2990px) {
    .membership-header {
        width: 100%;
        // height: 35vh !important;
    }
    .membership-title {
        font-size: 3.15rem !important;
    }
}

@media (min-width: 571px) and (max-width: 950px) {
    .membership-header {
        width: 100%;
        // height: 25vh !important;
    }
    .membership-title {
        font-size: 2rem !important;
    }
    .membership-details {
        padding: 0 10px !important;
    }
    .pd-3 {
        padding: 10px !important;
    }
    .ml-3 {
        margin-left: 0px !important;
    }
}

@media (min-width: 210px) and (max-width: 570px) {
    .membership-header {
        width: 100%;
        // height: 25vh !important;
    }
    .membership-title {
        font-size: 1rem !important;
    }
    .membership-details {
        padding: 0px !important;
    }
    .pd-3 {
        padding: 10px !important;
    }
    .ml-3 {
        margin-left: 0px !important;
    }
    .resp-afa {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0px;
    }
}

.link-color, 
.link-color:hover, 
.link-color:focus {
    color: blue;
    text-decoration: none;
}

.fs-20 {
    font-size: 20px;
}
